<template>
    <div
        ref="root"
        class="vi-uploadable-card"
        :class="{'uploaded': uploaded, 'rejected': !!rejectedReason, 'approved': approved}"
    >
        <span class="vi-uploadable-card-heading">{{ heading }}</span>

        <div class="vi-uploadable-card-instructions">
            <slot></slot>
        </div>

        <label :for="id" class="vi-uploadable-card-icon" :class="{'animated-icon': isUploading, 'upload-failed': uploadFailed }"  v-if="!approved">
            <i
                :class="{
                    'bi bi-check': uploaded && !isUploading,
                    'bi bi-cloud-upload': !uploaded || isUploading,
                }"
            ></i>
        </label>

        <p class="vi-uploadable-card-label mb-0" v-if="isUploading">Uploading...</p>

        <template v-else-if="!approved">
            <input type="file" :name="name" :id="id" class="hidden" @change.prevent="fileSelected" accept="image/*,.pdf" :multiple="multiple">
            <label class="vi-uploadable-card-label" :class="{'text-danger': uploadFailed}" :for="id">{{ uploadFailed ? 'Upload Failed. Please try again.': uploaded ? 'Replace Attachment': 'Browse Files' }}</label>
        </template>

        <div v-if="rejectedReason" class="mt-4 alert alert-danger text-center w-100">
            <h5>Reason for rejection</h5>
            <p class="mt-2">{{ rejectedReason }}</p>
        </div>

        <div v-else-if="approved" class="mt-4 alert alert-success text-center w-100">
            <h5>Accepted</h5>
        </div>
    </div>
</template>

<script>
import scroll_to from '../mixins/scroll_to';
export default {
    name: "ViUploadableCard",
    mixins: [
        scroll_to,
    ],
    props: {
        heading: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        uploaded: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            defautl: false,
        },
        isUploading: {
            type: Boolean,
            default: false,
        },
        uploadFailed: {
            type: Boolean,
            default: false,
        },
        rejectedReason: {
            type: String,
            default: null,
            required: false,
        },
        approved: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hovering: false,
            file: null,
            id: null,
        };
    },
    methods: {
        initListeners() {
            this.$refs.root.on('drop', function (event) {
                event.preventDefault();
            });
        },
        fileSelected(event) {
            let input = event.target;

            this.emitFileChosen(input.files);
        },
        turnOnHovering() {
            this.hovering = true;
        },
        turnOffHovering() {
            this.hovering = false;
        },
        emitFileChosen(file) {
            this.$emit('file-chosen', { file: Array.from(file) });
        }
    },
    computed: {
    },
    mounted() {
        this.id = this._uid;

        if (this.uploadFailed) {
            this.scrollIntoView(this.$refs.root);
        }
    },
    watch: {
        uploadFailed(newVal) {
            if (newVal) {
                this.scrollIntoView(this.$refs.root);
            }
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../sass/abstract/colours.scss';
.vi-uploadable-card {
    border: 4px dashed $bluef0;
    border-radius: 20px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    &-heading,
    &-instructions {
        color: $uctDarkBlue;
    }

    &-heading {
        font-size: 1.5rem;
        font-weight: 600;
    }

    &-instructions {
        font-size: 1rem;
    }

    &-icon {
        margin: 0;
        margin-top: auto;
        width: 3.5rem;
        font-size: 1.5rem;
        border: 3px solid $uctDarkBlue;
        color: $uctDarkBlue;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $bluef0;
        height: 3.5rem;
        overflow: hidden;

        &.upload-failed {
            border-color: $red;
            color: $red;
        }

        &.animated-icon {
            i {
                animation: animate 1s linear infinite;
            }

            @keyframes animate {
                0% {
                    transform: translateY(40px);
                }
                50% {
                    transform: translateY(0);
                }
                100% {
                    transform: translateY(-40px);
                }
            }

        }
    }

    &-label {
        position: relative;
        display: block;
        color: $uctDarkBlue;

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: "";
            display: block;
            height: 3px;
            width: 100%;
            bottom: 0;
            background-color: $uctDarkBlue;
        }
    }

    &.uploaded {
        background-color: $bluef0;
        border-style: solid;
    }

    &.uploaded & {
        &-icon {
            background-color: $white;

            .bi-check {
                font-size: 2rem;
            }
        }

    }

    &.approved {
        background-color: $dark-green-1;
        border-style: solid;
        border-color: $dark-green-1;

        & .vi-uploadable-card-heading,
        & .vi-uploadable-card-instructions,
        & .vi-uploadable-card-label {
            color: white;
        }
    }

    &.rejected {
        background-color: $red;
        border-style: solid;
        border-color: $red;

        & .vi-uploadable-card-heading,
        & .vi-uploadable-card-instructions,
        & .vi-uploadable-card-label {
            color: white;
            &.text-danger {
                color: white !important;
            }
        }
    }

    &.hovering {
        background-color: black;
    }
}
</style>